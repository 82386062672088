import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useValidUserTokenQuery } from "Store/MyApi";

function App(props) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const validToken = useValidUserTokenQuery();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      if (!validToken.isLoading) {
        if (validToken.isError) {
          dispatch({ type: "User/logout" });
        } else {
          dispatch({
            type: "User/setLoginToken",
            payload: store.User.loginToken,
          });
        }
        setInitialized(true);
      }
    }
  }, [
    dispatch,
    initialized,
    store.User.loginToken,
    validToken.isError,
    validToken.isLoading,
  ]);

  if (!initialized) {
    return null;
  }

  return props.children;
}

export default App;
