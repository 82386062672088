import { createSlice } from "@reduxjs/toolkit";

const TestStore = createSlice({
  name: "Test",
  initialState: {
    value: 0,
  },
  reducers: {
    setValue(state, payload) {
      state.value = payload.data;
    },
  },
});

export default TestStore;
