import { useEffect } from "react";
import {  useSelector } from "react-redux";
import MiroBlock from "./MiroBlock";

export default function PreviewReview(props) {
  const store = useSelector((state) => state);
  const miroData = props.miroData

  useEffect(()=>{
  },[store.Miro.my])


  return (
    <div className={` ${props.className} `}  >
      
        {miroData.map((item, i) =>
            <div className="tw-flex" key={i} >
                {item.map((block, l) => {


                    return <MiroBlock key={l}  data={{blockInfo:block,index_i:i,index_l:l}} />


                })}
            </div>
        )}

    </div>
  );
}
