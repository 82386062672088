import {Button, Input, message, Table, Tabs} from "antd";
import Password from "antd/es/input/Password";
import Column from "antd/es/table/Column";
import MainLayout from "Component/Layout/MainLayout";
import { decoding } from "Modul/Base64";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import {
  useEditLoginUserInfoMutation,
  useLoginUserInfoQuery,
  useMyCommentQuery,
  useMyPostQuery,
  useMyResolveHistoryQuery,
} from "Store/MyApi";
import profileImage from "../../static/img/profile.png"

const onChange = (key) => {
  console.log(key);
};

export default function MyPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const loginUserData = useLoginUserInfoQuery();

  console.log()
  const items = [
    {
      key: "1",
      label: <h3 className="tw-text-lg">개요</h3>,
      children: <Tab1 />,
    },

    {
      key: "2",
      label: <h3 className="tw-text-lg">설정</h3>,
      children: <Tab2 messageApi={messageApi} />,
    },
  ];
  return (
    <MainLayout>
      {contextHolder}
      <div className=" tw-border-solid tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-t tw-border-gray-400 tw-mt-8 tw-pt-4  tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-slate-100 tw-flex-col ">
        <div className=" tw-flex tw-w-3/6 tw-min-w-max ">
          <div className="tw-h-52  tw-border-gray-200 tw-w-72 tw-flex tw-justify-center tw-items-center ">
            <div className="tw-overflow-hidden tw-text-gray-600 tw-bg-white tw-h-44 tw-w-44 tw-rounded-full">
              <img className="-tw-scale-150 tw-pt-6 tw-p-0 tw-w-full tw-h-full" alt={''} src={profileImage} />
            </div>
          </div>
          <div className="tw-h-52 tw-border-gray-200 tw-grow tw-py-10 ">
            {
              !loginUserData.isLoading && <Profile user={loginUserData.data}/>
            }
          </div>
        </div>
        <Tabs
          className="tw-w-full tw-justify-center tw-items-center  "
          tabBarStyle={{
            margin: "0px",
          }}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </MainLayout>
  );
}
function Profile({user}) {
  return <>
    <h2 className="tw-mb-2">
      {user?.email}
    </h2>
    <p>
      작성글 : {user?.posts.length}
    </p>
    <p>
      해결한 문제 : {user?.resolveHistories.length}
    </p>
  </>
}
function CommonTabStyle(props) {
  return (
    <div className="tw-bg-white tw-w-screen tw-flex tw-flex-col tw-justify-center tw-items-center  ">
      {props.children}
    </div>
  );
}

function Tab1() {
  const myPostData = useMyPostQuery();
  const myCommentData = useMyCommentQuery();
  const myResolveHistory = useMyResolveHistoryQuery();

  return (
    <CommonTabStyle>
      {!myPostData.isLoading &&
      !myResolveHistory.isLoading &&
      !myCommentData.isLoading ? (
        <Tab1Content
          myPost={myPostData.data}
          myComment={myCommentData.data}
          myResolve={myResolveHistory.data}
        />
      ) : null}
    </CommonTabStyle>
  );
}

function Tab1Content({ myPost, myComment, myResolve }) {
  console.log(myPost);
  console.log(myComment);
  console.log(myResolve);
  const navigate = useNavigate();

  return (
    <div className="tw-w-4/5">
      <div className="tw-flex tw-justify-around ">
        <div className="tw-w-2/3 tw-p-4 ">
          <h2>내가 쓴 글</h2>
          <Table
            onRow={(record) => ({
              onClick: () => {
                let postUrl = "";

                if (record.board.type === "NORMAL") {
                  postUrl += "/post";
                } else if (record.board.type === "MIRO") {
                  postUrl += "/problem";
                }
                postUrl += "/view/" + record.id;

                navigate(postUrl);
              },
            })}
            rowKey="id"
            dataSource={myPost}
          >
            <Column width={100} title="번호" dataIndex="id" key="id" />

            <Column
              width={100}
              title="유형"
              dataIndex="board"
              key="type"
              render={(d) => {
                return d.type;
              }}
            ></Column>
            <Column
              width={100}
              title="게시판"
              dataIndex="board"
              key="board"
              render={(d) => {
                return d.name;
              }}
            ></Column>
            <Column title="제목" dataIndex="title" key="title" />
            <Column
              width={120}
              title="게시일"
              dataIndex="regDt"
              key="regDt"
              render={(d) => {
                const date = new Date(d);

                const year = date.getFullYear(); // 현재 연도
                const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
                const day = date.getDate(); // 현재 일

                return `${year}.${month}.${day}`;
              }}
            />
          </Table>
        </div>
        <div className="tw-w-1/3 tw-p-4 ">
          <h2>내가 푼 문제</h2>

          <Table
            onRow={(record) => ({
              onClick: () => {
                console.log(record.post);
                let postUrl = "";

                if (record.post.board.type === "NORMAL") {
                  postUrl += "/post";
                } else if (record.post.board.type === "MIRO") {
                  postUrl += "/problem";
                }
                postUrl += "/view/" + record.post.id;

                navigate(postUrl);
              },
            })}
            rowKey="id"
            dataSource={myResolve}
          >
            <Column width={100} title="번호" dataIndex="id" key="id" />

            <Column
              title="미로 게시물"
              dataIndex="post"
              key="post"
              render={(p) => {
                return p.title;
              }}
            />
            <Column
              width={120}
              title="게시일"
              dataIndex="regDt"
              key="regDt"
              render={(d) => {
                const date = new Date(d);

                const year = date.getFullYear(); // 현재 연도
                const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
                const day = date.getDate(); // 현재 일

                return `${year}.${month}.${day}`;
              }}
            />
          </Table>
        </div>
      </div>

      <div className="tw-p-4">
        <h2>내가 쓴 댓글</h2>

        <Table
          onRow={(record) => ({
            onClick: () => {
              console.log(record.post);
              let postUrl = "";

              if (record.post.board.type === "NORMAL") {
                postUrl += "/post";
              } else if (record.post.board.type === "MIRO") {
                postUrl += "/problem";
              }
              postUrl += "/view/" + record.post.id;

              navigate(postUrl);
            },
          })}
          rowKey="id"
          dataSource={myComment}
        >
          <Column width={100} title="번호" dataIndex="id" key="id" />

          <Column
            title="게시글"
            dataIndex="post"
            key="post"
            render={(d) => {
              return d.title;
            }}
          ></Column>
          <Column
            title="내용"
            dataIndex="content"
            key="content"
            render={(d) => {
              return decoding(d);
            }}
          ></Column>
        </Table>
      </div>
    </div>
  );
}

function Tab2({ messageApi }) {
  const loginUserData = useLoginUserInfoQuery();

  return (
    <CommonTabStyle>
      {!loginUserData.isLoading ? (
        <Tab2Content messageApi={messageApi} userData={loginUserData.data} />
      ) : null}
    </CommonTabStyle>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "MEMPW":
      state.memPw = action.value;
      break;
    case "CHANGEPASSWORD":
      state.changePassword = action.value;
      break;
    case "CHANGEPASSWORDCHECK":
      state.changePasswordCheck = action.value;
      break;
    case "EMAIL":
      state.email = action.value;
      break;
    case "MOBILE":
      state.mobile = action.value;
      break;
    case "ADDRESS":
      state.address = action.value;
      break;

    default:
      return state;
  }

  return { ...state };
}

function Tab2Content({ userData, messageApi }) {
  const [editMutation] = useEditLoginUserInfoMutation();

  const [loginUserData, dispatch] = useReducer(reducer, {
    ...userData,
    memPw: "",
    changePassword: "",
    changePasswordCheck: "",
  });

  function editUserPs() {
    editMutation(loginUserData).then(({ response, error }) => {
      if (error) {
        messageApi.error(error.data);
        return;
      }
      console.log(response);
      messageApi.success("적용되었습니다.");
      dispatch({ type: "MEMPW", value: "" });
      dispatch({ type: "CHANGEPASSWORD", value: "" });
      dispatch({ type: "CHANGEPASSWORDCHECK", value: "" });
    });
  }

  return (
    <div className="tw-w-2/3 tw-p-4 tw-flex tw-justify-center ">
      <div className="tw-w-1/2">
        <dl>
          <dt className="tw-mb-2 tw-mt-2">아이디</dt>
          <dd>
            <Input readOnly value={loginUserData.memId}></Input>
          </dd>
        </dl>
        <dl>
          <dt className="tw-mb-2 tw-mt-2">현재 비밀번호</dt>
          <dd>
            <Password
              value={loginUserData.memPw}
              onChange={(e) => {
                dispatch({ type: "MEMPW", value: e.target.value });
              }}
            ></Password>
          </dd>
        </dl>
        <dl>
          <dt className="tw-mb-2 tw-mt-2">비밀번호 변경</dt>
          <dd>
            <Password
              value={loginUserData.changePassword}
              onChange={(e) => {
                dispatch({ type: "CHANGEPASSWORD", value: e.target.value });
              }}
            ></Password>
          </dd>
        </dl>
        <dl>
          <dt className="tw-mb-2 tw-mt-2">비밀번호 변경 확인</dt>
          <dd>
            <Password
              value={loginUserData.changePasswordCheck}
              onChange={(e) => {
                dispatch({
                  type: "CHANGEPASSWORDCHECK",
                  value: e.target.value,
                });
              }}
            ></Password>
          </dd>
        </dl>
        <dl>
          <dt className="tw-mb-2 tw-mt-2">이메일</dt>
          <dd>
            <Input
              value={loginUserData.email}
              onChange={(e) => {
                dispatch({ type: "EMAIL", value: e.target.value });
              }}
            ></Input>
          </dd>
        </dl>
        <dl>
          <dt className="tw-mb-2 tw-mt-2">전화번호</dt>
          <dd>
            <Input
              onChange={(e) => {
                dispatch({ type: "MOBILE", value: e.target.value });
              }}
              value={loginUserData.mobile}
            ></Input>
          </dd>
        </dl>
        <dl>
          <dt className="tw-mb-2 tw-mt-2">주소</dt>
          <dd>
            <Input
              onChange={(e) => {
                dispatch({ type: "ADDRESS", value: e.target.value });
              }}
              value={loginUserData.address}
            ></Input>
          </dd>
        </dl>
        <dl>
          <dd className="tw-w-full tw-mt-5 ">
            <Button type="primary" className="tw-w-full" onClick={editUserPs}>
              적용
            </Button>
          </dd>
        </dl>
      </div>
    </div>
  );
}
