import { Button, Card, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import MainLayout from "Component/Layout/MainLayout";
import PreviewMiro from "Component/Miro/PreviewMiro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { decoding } from "Modul/Base64";
import { CommentOutlined } from "@ant-design/icons";
import {
  useGetCommentListQuery,
  useGetPostMutation,
  useResolveMiroComplateMutation,
  useWriteCommentMutation,
  useWriteReCommentMutation,
} from "Store/MyApi";

export default function ProblemView(props) {
  const store = useSelector((state) => state);
  const [inputValue, setInputValue] = useState();
  const dispatch = useDispatch();
  const keyboardEvent = InputKeyBoard(setInputValue);
  const [messageApi, contextHolder] = message.useMessage();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [resolveMiroComplate] = useResolveMiroComplateMutation();

  // const success = () => {
  //   messageApi.open({
  //     type: "success",
  //     content: "미로 찾기에 성공하였습니다.",
  //   });
  // };
  const path = useParams();

  const isGoal = () => {
    console.log(store.Miro.my);
    if (
      store.Miro.goal.x === store.Miro.my.x &&
      store.Miro.goal.y === store.Miro.my.y
    ) {
      console.log("도착!!");

      resolveMiroComplate(path.postId).then(function ({ data, error }) {
        if (data) {
          messageApi.success("미로찾기에 성공하였습니다.");
        }
        if (error) {
          switch (error.status) {
            case 403:
              messageApi.error("로그아웃 상태입니다.");
              break;
            default:
              messageApi.error("실패");
          }
        }
      });
    }
  };

  // const postData = useGetPostDataQuery(path.postId);

  // if (!postData.isFetching && !postData.isLoading) {
  //   if (postData.isError) {
  //     return <h1>ERR</h1>;
  //   } else {
  //     const responseData = postData.data;
  //     console.log(responseData);
  //     dispatch({
  //       type: "Miro/loadPostMiro",
  //       miroData: JSON.parse(responseData.miroData),
  //     });
  //     dispatch({ type: "Miro/initViewProblem" });
  //     dispatch({ type: "Miro/updatePlayMiroView" });
  //     setTitle(responseData.title);
  //     setContent(decoding(responseData.content));
  //   }
  // } else {
  //   return <h1>Loading....</h1>;
  // }

  const [postData] = useGetPostMutation();

  useEffect(() => {
    postData(path.postId)
      .then(function (resposne) {
        const responseData = resposne.data;
        dispatch({
          type: "Miro/loadPostMiro",
          miroData: JSON.parse(responseData.miroData),
        });
        dispatch({ type: "Miro/initViewProblem" });
        dispatch({ type: "Miro/updatePlayMiroView" });
        setTitle(responseData.title);
        setContent(decoding(responseData.content));
      })
      .then(function () {
        console.log("LoadComplatePostData");
      })
      .catch(function (err) {
        messageApi.error("미로를 불러오지 못했습니다.");
      });
  }, [dispatch, messageApi, path, postData, store.Main.server]);

  return (
    <MainLayout>
      {contextHolder}
      <main className=" tw-pt-7 tw-flex   tw-w-3/4 tw-justify-center  ">
        <div className="tw-flex tw-mb-9 tw-justify-center tw-w-full ">
          <ContentArea title={title} content={content}></ContentArea>

          <div className="tw-border-solid tw-mr-5 tw-border-gray-200 " />

          <div className="tw-mt-9  tw-flex tw-flex-col  ">
            {/* <Affix offsetTop={200}> */}
            <PreviewMiro
              className="tw-border-solid tw-border-gray-500   "
              miroData={store.Miro.playMiroView}
            ></PreviewMiro>
            <div className=" tw-mt-5 tw-flex tw-items-center">
              <Input
                value={inputValue}
                onKeyDown={(e) => {
                  keyboardEvent(e);
                }}
                autoFocus
                className=" tw-text-center tw-text-4xl tw-bg-white tw-bg-opacity-70 tw-w-20 tw-h-20   "
              ></Input>
              {store.Miro.goal.x === store.Miro.my.x &&
              store.Miro.goal.y === store.Miro.my.y ? (
                <Button
                  type="primary"
                  className=" tw-text-center tw-text-2xl tw-flex-grow tw-ml-5  tw-bg-opacity-70 tw-w-20 tw-h-20   "
                  onClick={isGoal}
                >
                  제출
                </Button>
              ) : (
                ""
              )}
            </div>
            {/* </Affix> */}
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
function ContentArea(props) {
  return (
    <div className="tw-mt-9  tw-flex-grow tw-mr-5 ">
      <div className="">
        <Card>
          <h2 className="">
            <span>Title. {props.title}</span>
            <span></span>
          </h2>
          <div
            className=""
            style={{ minHeight: "300px" }}
            dangerouslySetInnerHTML={{ __html: props.content }}
          ></div>
        </Card>
      </div>

      <div className=" tw-my-9 tw-border-solid tw-border-gray-200" />

      <CommentArea className=" tw-px-4"></CommentArea>
    </div>
  );
}

function CommentArea({ className }) {
  const path = useParams();

  let commentData = useGetCommentListQuery(path.postId);

  return !commentData.isLoading ? (
    <CommentAreaOnload className={className} commentData={commentData.data} />
  ) : null;
}
function CommentAreaOnload({ commentData, className }) {
  const [messageApi, contextHolder] = message.useMessage();

  console.log(commentData);
  const [reload, setReload] = useState(true);

  return (
    <div className={className}>
      {contextHolder}
      {commentData.map((c) => {
        return (
          <Comment
            key={c.id}
            user={c.user.memId}
            content={decoding(c.content)}
            commentId={c.id}
            reComment={c.reCommentList.map((rc) => {
              return {
                user: rc.user.memId,
                id: rc.id,
                content: decoding(rc.content),
              };
            })}
            reload={() => {
              setReload(!reload);
            }}
            showMessage={messageApi}
          />
        );
      })}

      <WriteCommentArea
        reload={() => {
          setReload(!reload);
        }}
      ></WriteCommentArea>
    </div>
  );
}

function Comment(props) {
  const [visiblReComment, setVisibleRecomment] = useState("none");
  const [reComment, setReComment] = useState("");
  const path = useParams();

  const [writeReCommentMutation] = useWriteReCommentMutation();

  const writeReComment = () => {
    writeReCommentMutation({
      commentId: props.commentId,
      content: reComment,
      postId: path.postId,
    })
      .then(function ({ data, error }) {
        if (data) {
          console.log(data);
          props.reload();
          setReComment("");
          setVisibleRecomment("none");
        }
        if (error) {
          props.messageApi.error("실패");
        }
      })
      .catch(function (err) {
        console.log(err);
        props.showMessage.error("실패");
      });
  };

  return (
    <Card key={props.commentId} className="tw-my-3" title={props.user}>
      <Card.Grid hoverable={false} style={{ width: "100%" }}>
        {props.content}
      </Card.Grid>

      {props.reComment.length !== 0 ? (
        <>
          <Card.Grid hoverable={false} style={{ width: "100%" }}>
            {props.reComment.map((t) => {
              return (
                <Card
                  hoverable={false}
                  className="tw-my-3"
                  key={t.id}
                  title={t.user}
                >
                  <div className="tw-p-5">{t.content}</div>
                </Card>
              );
            })}
          </Card.Grid>
        </>
      ) : (
        ""
      )}

      <Card.Grid
        hoverable={false}
        className="tw-flex"
        style={{ display: visiblReComment, width: "100%" }}
      >
        <TextArea
          value={reComment}
          onChange={(e) => {
            setReComment(e.target.value);
          }}
          className="tw-flex-grow tw-mr-4 "
          showCount
          maxLength={254}
        />
        <Button onClick={writeReComment} className="tw-h-auto" type="primary">
          덧글 쓰기
        </Button>
      </Card.Grid>

      <Card.Grid
        hoverable={false}
        style={{ padding: "10px" }}
        className="tw-flex tw-grow tw-justify-center"
      ></Card.Grid>

      <Card.Grid
        onClick={() => {
          if (visiblReComment === "none") {
            setVisibleRecomment("flex");
          } else {
            setVisibleRecomment("none");
          }
        }}
        style={{ padding: "10px", width: "fitContent" }}
        className="tw-flex  tw-justify-center"
      >
        <CommentOutlined style={{ fontSize: "20px" }} />
      </Card.Grid>
    </Card>
  );
}

function WriteCommentArea(props) {
  const path = useParams();

  const [content, setContent] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const onChange = (e) => {
    console.log("Change:", e.target.value);
    setContent(e.target.value);
  };

  const [writeCommentMutation] = useWriteCommentMutation();

  const writeComment = () => {
    console.log(content);
    console.log(content);

    writeCommentMutation({
      postId: path.postId,
      content: content,
    })
      .then(function ({ data, error }) {
        if (data) {
          console.log(data);
          props.reload();
        }

        if (error) {
          switch (error.status) {
            case 403:
              messageApi.error("로그아웃 상태입니다.");
              break;

            default:
              messageApi.error("실패");
          }
          messageApi.error("실패");
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <div className="tw-my-9 tw-flex">
      {contextHolder}
      <TextArea
        className="tw-flex-grow tw-mr-4 "
        showCount
        maxLength={254}
        onChange={onChange}
        value={content}
      />
      <Button onClick={writeComment} className="tw-h-auto" type="primary">
        덧글 쓰기
      </Button>
    </div>
  );
}

function InputKeyBoard(setInputValue) {
  const dispatch = useDispatch();

  const keyboardEvent = async (e) => {
    const key = e.code;

    switch (key) {
      case "ArrowUp":
        setInputValue("▲");
        dispatch({ type: "Miro/myLocationMove", direction: "up", length: 1 });
        break;

      case "ArrowDown":
        setInputValue("▼");

        dispatch({
          type: "Miro/myLocationMove",
          direction: "down",
          length: 1,
        });

        break;

      case "ArrowLeft":
        setInputValue("◀");

        dispatch({
          type: "Miro/myLocationMove",
          direction: "left",
          length: 1,
        });

        break;

      case "ArrowRight":
        setInputValue("▶");

        dispatch({
          type: "Miro/myLocationMove",
          direction: "right",
          length: 1,
        });

        break;

      default:
        console.log("err");
    }

    dispatch({ type: "Miro/updatePlayMiroView" });

    // console.log(store.Miro.my);
  };

  return keyboardEvent;
}
