import { Layout, Menu } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";

const { Header } = Layout;

const adminNav = [
  {
    key: 1,
    label: <Link to="/admin/main/"> 관리자 </Link>,
  },
  {
    key: 2,
    label: <Link to="/admin/main/callcenter"> 고객센터 </Link>,
  },
  {
    key: 3,
    label: <Link> 고객센터 </Link>,
  },
];

function Admin() {
  const store = useSelector((state) => state);
  const navigation = useNavigate();

  useEffect(() => {
    if (
      store.User.loginData.userType === "ADMIN" ||
      store.User.loginData.userType === "SUPERADMIN"
    ) {
      console.log("권한 확인 완료");
    } else {
      console.log("권한 확인 실패");
      navigation("/admin/login");
    }
  }, [navigation, store.User]);

  return (
    <Layout className="tw-h-screen tw-w-full ">
      <Header className="header tw-flex tw-items-center  ">
        <Link to="/admin/">
          <h1 className=" tw-mr-12 tw-font-normal tw-text-center tw-text-4xl tw-text-white ">
            MiroBaek
          </h1>
        </Link>

        <Menu
          className="tw-grow"
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={adminNav}
        />
        <h3 className=" tw-text-white tw-text-right ">
          <span className="tw-text-sm">{store.User.loginData.memId} </span>
          관리자님 환영합니다
        </h3>
      </Header>

      {/* 사이드바  */}
      <Outlet></Outlet>
    </Layout>
  );
}
export default Admin;
