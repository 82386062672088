import { Button, Table } from "antd";
import ManagementLayout from "Component/Layout/Admin/ManagementLayout";
import axiosInstance from "Modul/axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const dataSample = [];
for (let i = 0; i < 46; i++) {
  dataSample.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}

// const columnSample = [
//   {
//     title: "Name",
//     dataIndex: "name",
//     filters: [
//       {
//         text: "Joe",
//         value: "Joe",
//       },
//       {
//         text: "Jim",
//         value: "Jim",
//       },
//       {
//         text: "Submenu",
//         value: "Submenu",
//         children: [
//           {
//             text: "Green",
//             value: "Green",
//           },
//           {
//             text: "Black",
//             value: "Black",
//           },
//         ],
//       },
//     ],
//     // specify the condition of filtering result
//     // here is that finding the name started with `value`
//     onFilter: (value, record) => record.name.indexOf(value) === 0,
//     sorter: (a, b) => a.name.length - b.name.length,
//     sortDirections: ["descend"],
//   },
//   {
//     title: "Age",
//     dataIndex: "age",
//     defaultSortOrder: "descend",
//     sorter: (a, b) => a.age - b.age,
//   },
//   {
//     title: "Address",
//     dataIndex: "address",
//     filters: [
//       {
//         text: "London",
//         value: "London",
//       },
//       {
//         text: "New York",
//         value: "New York",
//       },
//     ],
//     onFilter: (value, record) => record.address.indexOf(value) === 0,
//   },
// ];

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "board",
    dataIndex: "board",
    //동적으로 수정 필요
    filters: [
      {
        text: "미로게시판",
        value: "미로게시판",
      },
      {
        text: "테스트 미로 겟판",
        value: "테스트 미로 겟판",
      },
    ],

    onFilter: (value, record) => record.board.indexOf(value) === 0,
  },
  {
    title: "title",
    dataIndex: "title",
  },
  {
    title: "user",
    dataIndex: "user",
  },
  {
    title: "modDt",
    dataIndex: "modDt",
  },
  {
    title: "view",
    dataIndex: "view",
  },
  {
    title: "Delete",
    dataIndex: "delete",
  },
];

export default function NormalPostList() {
  const store = useSelector((state) => state);

  const [data, setData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const deleteBtnPs = (postDto) => {
    axiosInstance()
      .post(`${store.Main.server}/admin/postmanagement/delete`, [
        { id: postDto.id },
      ])
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    axiosInstance()
      .post(`${store.Main.server}/admin/postmanagement/get`, {
        type: "NORMAL",
      })
      .then(function (response) {
        console.log(response);
        setData(
          response.data.map((d) => {
            d.board = d.board.name;
            d.user = d.user.memId;
            d.view = <Button type="primary">보기</Button>;
            d.delete = (
              <Button
                type="primary"
                onClick={() => {
                  deleteBtnPs(d);
                }}
                danger
              >
                삭제
              </Button>
            );
            return d;
          })
        );
      })
      .catch(function (err) {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <ManagementLayout>
      <Table
        rowKey={"id"}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      />
    </ManagementLayout>
  );
}
