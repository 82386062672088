import { Button, Table, Tabs } from "antd";

import { Link, useNavigate } from "react-router-dom";
import { useGetPostListQuery, useGetViewAbleBoardQuery } from "Store/MyApi";
import MainLayout from "../../Component/Layout/MainLayout";

export default function PostList() {
  const onChange = (key) => {
    console.log(key);
  };

  const viewAbleBoard = useGetViewAbleBoardQuery("NORMAL");

  return (
    <MainLayout>
      <main className="tw-flex tw-w-full tw-flex-col tw-items-center ">
        <section className="tw-w-2/3 fw-flex tw-mt-4  ">
          <Tabs
            tabBarExtraContent={
              <Link to="/post/write">
                <Button>추가</Button>
              </Link>
            }
            defaultActiveKey="1"
            items={
              viewAbleBoard.isLoading
                ? null
                : viewAbleBoard.error
                ? null
                : viewAbleBoard.data.map((board) => {
                    return {
                      key: board.id,
                      label: board.name,
                      children: <PostTable boardId={board.id}></PostTable>,
                    };
                  })
            }
            onChange={onChange}
          />
        </section>
      </main>
    </MainLayout>
  );
}

const columns = [
  {
    title: "num",
    dataIndex: "id",
    key: "id",
    width: 130,
  },
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "유저",
    dataIndex: "user",
    key: "user",
    width: 150,
  },
  {
    title: "생성일",
    dataIndex: "modDt",
    key: "modDt",
    width: 150,
  },
];

function PostTable(props) {
  const navigate = useNavigate();

  const postList = useGetPostListQuery(props.boardId);

  const rowClick = (record) => {
    console.log(record);
    navigate(`/post/view/${record.id}`);
  };

  return (
    <Table
      loading={postList.isLoading}
      dataSource={
        postList.isLoading
          ? null
          : postList.error
          ? null
          : postList.data.map((post) => {
              const date = new Date(post.modDt);

              const year = date.getFullYear(); // 현재 연도
              const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
              const day = date.getDate(); // 현재 일

              const formattedDate = `${year}.${month}.${day}`;

              return {
                id: post.id,
                title: post.title,
                user: post.user.memId,
                modDt: formattedDate,
              };
            })
      }
      rowKey="id"
      columns={columns}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => rowClick(record, rowIndex), // click row
        };
      }}
    />
  );
}
