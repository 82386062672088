import { Button, Checkbox, Divider, Input, Table } from "antd";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import ManagementLayout from "Component/Layout/Admin/ManagementLayout";
import axios from "Modul/axios";

export default function NormalBoardList() {
  const store = useSelector((state) => state);

  const [selectTableRow, setSelectTableRow] = useState([]);
  const [reload,setReload]=useState([])

  const reducer = (state, payload) => {
    switch (payload.type) {
      case "init":
        console.log(payload);
        return [...payload.data];
      case "edit":

        const editData = state[payload.index];

        const newData = { ...editData, [payload.col]: payload.data };

        state[payload.index] = newData;


        return [...state];

      default:
        alert("err");
    }
  };

  const [data, dispatch] = useReducer(reducer, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => text,
    },
    {
      title: "NAME",
      dataIndex: "name",
      render: (value, data, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => {
              dispatch({
                type: "edit",
                index: index,
                col: "name",
                data: e.target.value,
              });
            }}
          ></Input>
        );
      },
    },
    {
      title: "ACTIVE",
      dataIndex: "active",
      render: (value, data, index) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) => {
              dispatch({
                type: "edit",
                index: index,
                col: "active",
                data: !value,
              });
            }}
          ></Checkbox>
        );
      },
      align: "center",
    },
    {
      title: "COMMENT",
      dataIndex: "comment",
      render: (value, data, index) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) => {
              dispatch({
                type: "edit",
                index: index,
                col: "comment",
                data: !value,
              });
            }}
          ></Checkbox>
        );
      },
      align: "center",
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      render: (value,data,index) => (
        <Button type="primary" danger
          onClick={()=>{
            console.log([data])
            axios().put(`${store.Main.server}/admin/boardmanagement/delete`,[data])
            .then(function(resposne){
              console.log(resposne)
              setReload(!reload)
            })
            .catch(function(err){
              console.log(err)
            })
          }}
        >
          삭제
        </Button>
      ),
      align: "center",
    },
    {
      title: (
        <Button
          type="default"
          onClick={() => {
            console.log(selectTableRow);
            let selectData = [];
            for (let index of selectTableRow) {

              selectData.push(...data.map((t) => {
                if (t.id === index) {
                  return t;
                } else {
                  return null;
                }
              }).filter(f=>f!==null))

            }
            axios()
              .put(
                `${store.Main.server}/admin/boardmanagement/edit`,selectData)
              .then(function (resposne) {
                console.log(resposne);
              })
              .catch(function (err) {
                console.log(err);
              });
          }}
        >
          일괄적용
        </Button>
      ),
      dataIndex: "delete",
      render: (value, data, index) => (
        <Button
          type="primary"
          onClick={(e) => {
            console.log([data]);
            


            axios()
              .put(
                `${store.Main.server}/admin/boardmanagement/edit`,[data])
              .then(function (resposne) {
                console.log(resposne);
              })
              .catch(function (err) {
                console.log(err);
              });
          }}
        >
          적용
        </Button>
      ),
      align: "center",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows);
      setSelectTableRow(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    console.log("리로드");
    axios().post(`${store.Main.server}/admin/boardmanagement/findbytype`,{ type: "NORMAL" })
      .then(function (response) {
        const data = response.data;

        dispatch({ type: "init", data: data });
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [store.Main.server, store.User.loginToken,reload]);

  return (
    <ManagementLayout>
      <h1>일반 게시판 리스트</h1>
      <div className="tw-mt-5">
        <Divider />

        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey="id"
          columns={columns}
          dataSource={data}
        />
      </div>
    </ManagementLayout>
  );
}

// const data = [
//   {
//     id: 1,
//     name: "게시판 1",
//     active: true,
//     comment: false,
//   },
//   {
//     id: 2,
//     name: "게시판 2",
//     active: true,
//     comment: true,
//   },
// ];
