import { message } from "antd";

export default function MessageApi() {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 10,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const warning = (message) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  const info = (message) => {
    messageApi.info(message);
  };

  return { contextHolder, success, error, warning, info };
}
