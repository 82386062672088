import { Button, Input, Select } from "antd";
import ManagementLayout from "Component/Layout/Admin/ManagementLayout";
import { useReducer } from "react";
import { useAddAdminMutation } from "Store/MyApi";

const initialState = {
  memId: "",
  memPw: "123456789",
  memPwRe: "",
  email: "",
  mobile: "",
  address: "admin",
  userType: "ADMIN",
};

const actionTypes = {
  SETMEMID: "SETMEMID",
  SETMEMPW: "SETMEMPW",
  SETMEMPWRE: "SETMEMPWRE",
  SETEMAIL: "SETEMAIL",
  SETMOBILE: "SETMOBILE",
  SETUSERTYPE: "SETUSERTYPE",
};

const reducer = (state, action) => {
  // console.log(state);
  // console.log(action);
  // eslint-disable-next-line default-case
  switch (action.type) {
    case actionTypes.SETEMAIL:
      return { ...state, email: action.data };

    case actionTypes.SETMEMID:
      return { ...state, memId: action.data };
    case actionTypes.SETMEMPW:
      return { ...state, memPw: action.data };
    case actionTypes.SETMEMPWRE:
      return { ...state, memPwRe: action.data };
    case actionTypes.SETMOBILE:
      return { ...state, mobile: action.data };
    case actionTypes.SETUSERTYPE:
      return { ...state, userType: action.data };

    case actionTypes.default:
      return state;
  }
};

export default function AddAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addAdminMutation] = useAddAdminMutation();
  const style = {
    dl: "",
    dt: "",
    dd: "",
  };

  function addAdminPs() {
    console.log(state);
    addAdminMutation(state).then((res) => {
      console.log(res);
    });
  }

  return (
    <ManagementLayout>
      <h1>관리자 추가</h1>
      <div className=" tw-w-full tw-h-full tw-flex tw-flex-col  tw-justify-center tw-items-center">
        <div className="tw-ml-3 tw-w-1/3 tw-h-1/2 ">
          <dl className={(style.dl = " tw-flex tw-my-4  ")}>
            <dt
              className={
                (style.dt =
                  " tw-mr-5 tw-w-32 tw-flex tw-items-center tw-justify-between after:tw-content-[''] after:tw-inline-block after:tw-h-full after:tw-w-1 after:tw-bg-gray-200")
              }
            >
              아이디
            </dt>

            <dd className={(style.dd = "tw-flex-grow")}>
              <Input
                onChange={(e) => {
                  dispatch({
                    type: actionTypes.SETMEMID,
                    data: e.target.value,
                  });
                }}
              />
            </dd>
          </dl>
          <dl className={style.dl}>
            <dt className={style.dt}>비밀번호</dt>

            <dd className={style.dd}>
              <Input.Password
                onChange={(e) => {
                  dispatch({
                    type: actionTypes.SETMEMPW,
                    data: e.target.value,
                  });
                }}
              />
            </dd>
          </dl>
          <dl className={style.dl}>
            <dt className={style.dt}>비밀번호 확인</dt>

            <dd className={style.dd}>
              <Input.Password
                onChange={(e) => {
                  dispatch({
                    type: actionTypes.SETMEMPWRE,
                    data: e.target.value,
                  });
                }}
              />
            </dd>
          </dl>
          <dl className={style.dl}>
            <dt className={style.dt}>이메일</dt>

            <dd className={style.dd}>
              <Input
                onChange={(e) => {
                  dispatch({
                    type: actionTypes.SETEMAIL,
                    data: e.target.value,
                  });
                }}
              />
            </dd>
          </dl>
          <dl className={style.dl}>
            <dt className={style.dt}>휴대전화 번호</dt>

            <dd className={style.dd}>
              <Input
                onChange={(e) => {
                  dispatch({
                    type: actionTypes.SETMOBILE,
                    data: e.target.value,
                  });
                }}
              />
            </dd>
          </dl>
          <dl className={style.dl}>
            <dt className={style.dt}>권한</dt>

            <dd className={style.dd}>
              <Select
                className="tw-w-full"
                options={[
                  { value: "ADMIN", label: "ADMIN" },
                  { value: "SUPERADMIN", label: "SUPERADMIN" },
                ]}
                onChange={(e) => {
                  console.log(e);
                  dispatch({ type: actionTypes.SETUSERTYPE, data: e });
                }}
                value={{ value: state.userType, label: state.userType }}
              ></Select>
            </dd>
          </dl>
          <Button onClick={addAdminPs} className="tw-w-full" type="primary">
            {state.userType} 추가
          </Button>
        </div>
      </div>
    </ManagementLayout>
  );
}
