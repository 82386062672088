import axios from "axios";

const AxiosInstance = () => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error(error.response.status);
      // alert("로그인이 필요한 서비스 입니다.");
      return Promise.reject(error);
    }
  );

  return instance;
};

export default AxiosInstance;
