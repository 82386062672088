import { Input, message, Modal, Space } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Join from "./Join";

export default function Login() {
  const store = useSelector((state) => state);

  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const loginFaile = () => {
    messageApi.open({
      type: "error",
      content: "아이디 또는 비밀번호를 확인해 주세요",
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loginRequest, setLoginRequest] = useState({ memId: "", memPw: "" });

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loginPs = () => {
    console.log("로그인");
    axios
      .post(`${store.Main.server}/user/login`, loginRequest)
      .then((response) => {
        let loginToken = response.data;
        console.log(loginToken);

        dispatch({ type: "User/setLoginToken", payload: loginToken });
      })
      .catch((err) => {
        console.log(err);
        loginFaile();
      });
  };

  return (
    <div className="  tw-flex tw-flex-col tw-justify-center tw-h-full tw-p-3">
      <h1 className="tw-text-3xl tw-m-3">로그인</h1>
      {contextHolder}
      <div className="">
        <Input
          value={loginRequest.memId}
          onChange={(e) => {
            setLoginRequest({ ...loginRequest, memId: e.currentTarget.value });
          }}
          className="tw-my-2"
          placeholder="아이디를 입력하세요"
        ></Input>
        <Input.Password
          value={loginRequest.memPw}
          onChange={(e) => {
            setLoginRequest({ ...loginRequest, memPw: e.currentTarget.value });
          }}
          className="tw-my-2"
          type="password"
          placeholder="비밀번호를 입력하세요"
        ></Input.Password>
      </div>

      <Space wrap className="tw-justify-end tw-my-2 ">
        <button
          onClick={setIsModalOpen}
          className="tw-border-0 tw-py-1.5 tw-w-24 tw-bg-blue-500 tw-text-white  tw-p-1 tw-rounded-md hover:tw-bg-blue-400 tw-transition-all "
        >
          회원가입
        </button>
        <button
          onClick={loginPs}
          className=" tw-border-0 tw-py-1.5 tw-active tw-w-24 tw-bg-green-500 tw-text-white tw-p-1 tw-rounded-md hover:tw-bg-green-400 tw-transition-all"
        >
          로그인
        </button>
      </Space>

      <Modal
        centered
        footer
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Join
          joinScCallBack={() => {
            handleCancel();
            const success = () => {
              messageApi.open({
                type: "success",
                content: "회원가입 성공",
              });
            };
            success();
          }}
        ></Join>
      </Modal>
    </div>
  );
}
