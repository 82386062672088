import { useDispatch } from "react-redux";
import MiroBlock from "./MiroBlock";
import { Button } from "antd";

export default function EditMiro(props) {
  const dispatch=useDispatch()
  const miroData = props.miroData

  return (
    <div >
      <div>
        {/*<Button onClick={()=>{*/}
        {/*  console.log(miroData)*/}
        {/*}} > 미로 데이터 </Button>*/}
        <Button onClick={()=>{
          let del = Number(prompt("1: 길, 2:출발, 3:도착"))
          dispatch({type:"Miro/resetMiro",del:del})
        }} >지우기</Button>
        <Button onClick={()=>{
          dispatch({type:"Miro/saveMiro"})
        }} >임시 저장</Button>
        <Button onClick={()=>{
          dispatch({type:"Miro/loadMiro"})
        }} >로드</Button>

      </div>
        {miroData.map((item, i) =>
            <div className="tw-flex"  key={i} >
                {item.map((block, l) => {

                  const clickBlockEv=()=>{
                    dispatch({type:"Miro/editBlock",i:i,l:l})
                  }

                  return <MiroBlock key={l} clickBlockEv={clickBlockEv} data={{blockInfo:block,index_i:i,index_l:l}} />
                })}
            </div>
        )}

    </div>
  );
}
