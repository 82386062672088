import { Button, Table } from "antd";
import ManagementLayout from "Component/Layout/Admin/ManagementLayout";
import axiosInstance from "Modul/axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetPostListByTypeQuery } from "Store/MyApi";

const dataSample = [];
for (let i = 0; i < 46; i++) {
  dataSample.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: "100px",
  },
  {
    title: "board",
    dataIndex: "board",
    //동적으로 수정 필요
    filters: [
      {
        text: "미로게시판",
        value: "미로게시판",
      },
      {
        text: "테스트 미로 겟판",
        value: "테스트 미로 겟판",
      },
    ],
    width: "130px",

    onFilter: (value, record) => record.board.indexOf(value) === 0,
  },
  {
    title: "title",
    dataIndex: "title",
  },
  {
    title: "user",
    dataIndex: "user",
    width: "140px",
  },
  {
    title: "modDt",
    dataIndex: "modDt",
    render: (t) => {
      const date = new Date(t);

      const year = date.getFullYear(); // 현재 연도
      const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
      const day = date.getDate(); // 현재 일

      return `${year}.${month}.${day}`;
    },
    width: "120px",
  },
  {
    title: "view",
    dataIndex: "view",
    width: "100px",
  },
  {
    title: "Delete",
    dataIndex: "delete",
    width: "100px",
  },
];

export default function MiroPostList() {
  const postData = useGetPostListByTypeQuery("MIRO");

  return (
    <ManagementLayout>
      {!postData.isLoading ? (
        <MiroPostListContent postData={postData.data} />
      ) : null}
    </ManagementLayout>
  );
}

export function MiroPostListContent({ postData }) {
  const store = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const deleteBtnPs = (postDto) => {
    axiosInstance()
      .post(`${store.Main.server}/admin/postmanagement/delete`, [
        { id: postDto.id },
      ])
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  console.log(postData);

  const formatData = postData.map((d) => {
    console.log(d);
    const data = {
      title: d.title,
      id: d.id,
      modDt: d.modDt,
      board: d.board.name,
      user: d.user.memId,
      view: <Button type="primary">보기</Button>,
      delete: (
        <Button
          type="primary"
          onClick={() => {
            deleteBtnPs(d);
          }}
          danger
        >
          삭제
        </Button>
      ),
    };

    return data;
  });

  return (
    <Table
      rowKey={"id"}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={formatData}
    />
  );
}
