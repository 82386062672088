import { Button } from "antd";
import { useDispatch } from "react-redux";
import MiroBlock from "./MiroBlock";

export default function ResolveMiro(props) {
  const dispatch = useDispatch();
  const miroData = props.miroData;

  function reset() {
    dispatch({ type: "Miro/resetMiro", del: 4 });
    dispatch({ type: "Miro/resetMiro", del: 5 });
  }

  function resolveFun(e, i, l) {
    if (i) {
      console.log("실행중");
    } else {
      console.log("경로찾기 시작");
    }

    //시작 좌표

    i = undefined;
    l = undefined;

    try {
      i = !i ? miroData.findIndex((item) => item.indexOf(2) !== -1) : i;
      l = !l ? miroData[i].findIndex((item) => item === 2) : l;
    } catch (error) {
      alert("출발점, 도착점 을 설정해주세요");
      return;
    }

    searchRoad(e, i, l, []);
  }

  function searchRoad(e, i, l, history) {
    const detection = [
      [
        miroData[i + 1] !== undefined
          ? miroData[i + 1][l] !== undefined
            ? miroData[i + 1][l]
            : 1
          : 1,
        i + 1,
        l,
      ],
      [
        miroData[i - 1] !== undefined
          ? miroData[i - 1][l] !== undefined
            ? miroData[i - 1][l]
            : 1
          : 1,
        i - 1,
        l,
      ],
      [
        miroData[i] !== undefined
          ? miroData[i][l - 1] !== undefined
            ? miroData[i][l - 1]
            : 1
          : 1,
        i,
        l - 1,
      ],
      [
        miroData[i] !== undefined
          ? miroData[i][l + 1] !== undefined
            ? miroData[i][l + 1]
            : 1
          : 1,
        i,
        l + 1,
      ],
    ];

    if (
      detection[0][0] === 3 ||
      detection[1][0] === 3 ||
      detection[2][0] === 3 ||
      detection[3][0] === 3
    ) {
      // alert("길이 존재합니다");
      dispatch({ type: "Miro/setValidMiro", value: "true" });
      // console.log(history);
      const resultArr = history.reduceRight((accumulator, currentValue) => {
        let search_i =
          accumulator[accumulator.length - 1] === undefined
            ? i
            : accumulator[accumulator.length - 1].from.i;
        let search_l =
          accumulator[accumulator.length - 1] === undefined
            ? l
            : accumulator[accumulator.length - 1].from.l;

        const curTo = currentValue.to;

        // console.log(search_i, search_l);
        if (search_i === curTo.i && search_l === curTo.l) {
          accumulator.push(currentValue);

          setTimeout(() => {
            dispatch({
              type: "Miro/solutionMiro",
              i: currentValue.to.i,
              l: currentValue.to.l,
            });
          }, 500);
        }

        return accumulator;
      }, []);
      console.log(resultArr);

      return;
    }

    if (
      detection[0][0] !== 0 &&
      detection[1][0] !== 0 &&
      detection[2][0] !== 0 &&
      detection[3][0] !== 0
    ) {
      console.log("막다른 길입니다 해당 뭐시글 종료합니다");
      return;
    }

    // console.log("현위치", miroData[i][l], i, l);
    // console.log(
    //   "아래\t",
    //   ...detection[0],
    //   "\n위\t\t",
    //   ...detection[1],
    //   "\n왼\t\t",
    //   ...detection[2],
    //   "\n오\t\t",
    //   ...detection[3]
    // );

    for (let k = 0; k < detection.length; k++) {
      let [data, x_loc, y_loc] = detection[k];
      if (data === 0) {
        const inner = setTimeout(() => {
          try {
            dispatch({ type: "Miro/resolveMiro", i: x_loc, l: y_loc });

            history.push({ from: { i: i, l: l }, to: { i: x_loc, l: y_loc } });

            searchRoad(e, x_loc, y_loc, history);
          } catch (error) {
            clearTimeout(inner);
          }
        }, 10);
      }
    }

    return;
  }

  return (
    <div className="">
      <div className="tw-my-2">
        <Button className="tw-mr-3" type="primary" onClick={resolveFun}>
          시작
        </Button>
        <Button onClick={reset}>테스트 내용 지우기</Button>
      </div>

      <div className="">
        {miroData.map((item, i) => (
          <div className="tw-flex" key={i}>
            {item.map((block, l) => {
              // const clickBlockEv = () => {
              //   dispatch({ type: "Miro/resolveMiro", i: i, l: l });
              // };

              return (
                <MiroBlock
                  key={l}
                  data={{ blockInfo: block, index_i: i, index_l: l }}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
