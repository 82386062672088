import { Breadcrumb, Layout,theme } from "antd";
import { Content } from "antd/es/layout/layout";

export default function ManagementLayout(props){
    const {
        token: { colorBgContainer },
      } = theme.useToken();

    return(
        
        <Layout
        style={{
          padding: "0 24px 24px",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        ></Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    )
}