import { useSelector } from "react-redux";

export default function ComponentTest() {
  const store = useSelector((state) => state);


  return (
    <>
      <h1>ㅇㅇ</h1>
      {store.Test.value}
    </>
  );
}
