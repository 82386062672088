import MainLayout from "../Component/Layout/MainLayout";
import "../index.css";
import "swiper/css";

export default function Main() {
  return (
    <MainLayout>
      {/* <main className=" tw-flex tw-items-center tw-flex-col    "> */}
      {/* <OtherProject></OtherProject> */}
      <section
        className="tw-min-w-max tw-w-2/3  tw-bg-gray-700 tw-p-3   tw-flex tw-flex-row  tw-mt-5 tw-overflow-hidden "
        style={{ height: "568px" }}
      >
        <div
          style={{ transform: "rotate(35deg)" }}
          className=" tw-flex tw-items-center tw-justify-center tw-w-96 tw-h-96 tw-border-solid tw-border-white tw-border-4 "
        >
          <div className="tw-w-72 tw-h-72 tw-rounded-xl tw-border-solid tw-border-white tw-border-4 "></div>
        </div>
        <div className="tw-flex tw-flex-col tw-grow tw-justify-center tw-items-center">
          <h1 className=" tw-text-center tw-text-white tw-text-6xl  tw-font-medium  ">
            MiroBaek Project
          </h1>
          <div className="tw-mt-7 tw-border-white tw-border-solid tw-border-0 tw-border-t-2  tw-w-full "></div>
        </div>
      </section>

      <Comment></Comment>

      {/*<div className="tw-w-full tw-flex tw-justify-around tw-items-center  tw-h-96 tw-bg-gray-800 tw-p-20 ">*/}
      {/*  <div className="tw-border-solid tw-h-80 tw-w-80 tw-border-white tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white tw-bg-gray-900   ">*/}
      {/*    <h2 className="tw-w-4/5 tw-text-left">정민상</h2>*/}
      {/*    <h3 className="tw-w-4/5">010-7517-5563</h3>*/}
      {/*    <h3 className="tw-w-4/5">jmspon33@gmail.com</h3>*/}
      {/*  </div>*/}
      {/*  <div className="tw-border-solid tw-h-80 tw-w-80 tw-border-white tw-flex tw-flex-wrap  tw-justify-center tw-items-center tw-p-2 tw-bg-gray-900">*/}
      {/*    <img className=" tw-p-1 tw-w-1/2 tw-h-1/2" src={reactIcon}></img>*/}
      {/*    <img className="tw-p-1 tw-w-1/2 tw-h-1/2" src={springIcon}></img>*/}
      {/*    <img className="tw-p-1 tw-w-1/2 tw-h-1/2" src={mysqlIcon}></img>*/}
      {/*  </div>*/}
      {/*  <a*/}
      {/*    href="https://gitlab.bmserver.org/"*/}
      {/*    className="tw-border-solid tw-h-80 tw-w-80 tw-border-white tw-flex tw-justify-center tw-items-center tw-bg-gray-900"*/}
      {/*  >*/}
      {/*    <img src={gitlabIcon}></img>*/}
      {/*  </a>*/}
      {/*</div>*/}
      {/* </main> */}
    </MainLayout>
  );
}

// function OtherProject() {
//   return (
//     <section
//       className="tw-w-2/3  tw-bg-zinc-100 tw-p-3   tw-flex tw-flex-col  tw-mt-5 tw-overflow-hidden "
//       style={{ height: "568px" }}
//     >
//       <div className="tw-bg-zinc-500 tw-text-white tw-px-4 tw-p-2">
//         다른 프로젝트
//       </div>
//       <div className="tw-flex tw-w-full tw-h-full ">
//         <div className=" tw-flex tw-flex-col tw-w-32 tw-mr-4 ">
//           <button className="tw-py-5 tw-border-b-2 tw-w-full ">
//             프로젝트1
//           </button>
//           <button className="tw-py-5 tw-border-b-2 tw-w-full ">
//             프로젝트2
//           </button>
//           <button className="tw-py-5 tw-border-b-2 tw-w-full ">
//             프로젝트3
//           </button>
//           <button className="tw-py-5">프로젝트4</button>
//         </div>
//
//         <div className="tw-h-full tw-flex  tw-flex-grow tw-bg-zinc-100 tw-overflow-hidden  ">
//           <img
//             className="tw-h-full tw-overflow-x-hidden"
//             src={portfolio1}
//             alt="프로젝트 이미지"
//           ></img>
//         </div>
//
//         <div className="tw-flex tw-bg-white tw-items-center tw-flex-col tw-justify-center tw-w-56    ">
//           <div className="tw-flex  tw-justify-between tw-flex-col tw-w-1/2 tw-h-1/2">
//             <Button> 이동 </Button>
//
//             <Button> Git </Button>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function Comment() {
  return (
    <div className="tw-h-72  tw-flex tw-flex-col tw-justify-center  tw-items-center ">
      <p className="tw-text-4xl tw-text-gray-600 tw-text-center ">
        MiroBaek Online Judge
      </p>
      <div className="tw-bg-gray-600 tw-w-36 tw-h-1 tw-mb-10 tw-mt-4 "></div>
      <p className="tw-text-gray-600 -tw-tracking-tighter tw-text-center  ">
        미로 문제를 풀고 온라인으로 채점받을 수 있는 곳입니다.
      </p>
    </div>
  );
}
