import {  Modal } from "antd";
import { useState } from "react";
import Login from "./Login";

export default function LoginPopup(){
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <button type="primary" onClick={showModal}>
          로그인 / 회원가입
        </button>
        <Modal centered footer open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Login></Login>
        </Modal>
      </>
    );
}