import ManagementLayout from "../../../../Component/Layout/Admin/ManagementLayout";
import {
  Button,
  Drawer,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tabs,
} from "antd";
import {
  useEditCommentAdminMutation,
  useEditPostAdminMutation,
  useEditUserInfoAdminMutation,
  useGetUserInfoAdminQuery,
  useGetUserListQuery,
  useGetViewAbleBoardQuery,
} from "Store/MyApi";
import { useEffect, useReducer, useState } from "react";
import { decoding, encoding } from "Modul/Base64";
import TextArea from "antd/es/input/TextArea";

export default function UserList() {
  const superAdminList = useGetUserListQuery("SUPERADMIN");
  const adminList = useGetUserListQuery("ADMIN");

  return (
    <ManagementLayout>
      {!superAdminList.isLoading && !adminList.isLoading ? (
        <Content superAdminList={superAdminList} adminList={adminList} />
      ) : null}
    </ManagementLayout>
  );
}

function Content({ superAdminList, adminList }) {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: "UserId",
      dataIndex: "memId",
      key: "memId",
      width: "50%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
    },

    {
      title: "RegDt",
      dataIndex: "regDt",
      key: "regDt",
      render: (dt) => {
        const date = new Date(dt);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${year}.${month}.${day}`;
      },
      width: "10%",
    },
    {
      title: "Edit",
      dataIndex: "Edit",
      key: "Edit",
      render: (t, l) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setSelectUser(l.id);
              setOpen(true);
            }}
          >
            Open
          </Button>
        );
      },
      width: "10%",
    },
  ];
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const [selectUser, setSelectUser] = useState(-1);

  return (
    <>
      <h1>SUPERADMIN</h1>
      <Table
        columns={columns}
        loading={superAdminList.isLoading}
        dataSource={superAdminList.data}
        rowKey={"id"}
      />
      <h1>ADMIN</h1>
      <Table
        columns={columns}
        loading={adminList.isLoading}
        dataSource={adminList.data}
        rowKey={"id"}
      />
      {selectUser !== -1 ? (
        <UserInfoSidbar onClose={onClose} open={open} selectUser={selectUser} />
      ) : null}
    </>
  );
}

function UserInfoSidbar({ onClose, open, selectUser }) {
  const userData = useGetUserInfoAdminQuery(selectUser);

  return userData.isLoading ? null : (
    <UserInfoSidbarContent
      onClose={onClose}
      open={open}
      selectUser={selectUser}
      userData={userData.data}
    />
  );
}

function userDataReducerFn(state, action) {
  const ACTION = {
    EMAIL: "EMAIL",
    MOBILE: "MOBILE",
    USERTYPE: "USERTYPE",
    ADDRESS: "ADDRESS",
  };

  switch (action.type) {
    case ACTION.EMAIL:
      state.email = action.value.target.value;
      break;
    case ACTION.MOBILE:
      state.mobile = action.value.target.value;
      break;
    case ACTION.USERTYPE:
      state.userType = action.value;
      break;
    case ACTION.ADDRESS:
      state.address = action.value.target.value;
      break;
    case "ALL":
      state = { ...action.value };
      break;
    default:
      break;
  }
  return { ...state };
}

const userTabChange = (key) => {
  console.log(key);
};

function UserInfoSidbarContent({ onClose, open, userData }) {
  const [userDataReducer, dispatch] = useReducer(userDataReducerFn, {
    ...userData,
  });
  const changeValue = (e, action) => {
    dispatch({ value: e, type: action });
  };
  useEffect(() => {
    dispatch({ type: "ALL", value: userData });
  }, [userData]);

  const userInfoTab = [
    {
      key: "1",
      label: `유저 정보`,
      children: (
        <UserInfoTabContent
          changeValue={changeValue}
          userDataReducer={userDataReducer}
        />
      ),
    },
    {
      key: "2",
      label: `작성한 글`,
      children: <UserPostTabContent userDataReducer={userDataReducer} />,
    },
    {
      key: "3",
      label: `작성한 댓글`,
      children: <UserCommentTabContent userDataReducer={userDataReducer} />,
    },
  ];

  return (
    <Drawer
      title={userData.memId}
      placement="right"
      width={900}
      onClose={onClose}
      open={open}
    >
      <Tabs defaultActiveKey="1" items={userInfoTab} onChange={userTabChange} />
    </Drawer>
  );
}

function UserInfoTabContent({ userDataReducer, changeValue }) {
  const [messageApi, contextHolder] = message.useMessage();

  const [mutation] = useEditUserInfoAdminMutation();

  return (
    <div className="tw-w-1/2">
      {contextHolder}
      <dl className="tw-my-2">
        <dt className="tw-my-2">시퀀스</dt>
        <dd>
          <Input value={userDataReducer.id}></Input>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2"> 아이디</dt>
        <dd>
          <Input value={userDataReducer.memId}></Input>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2">이메일</dt>
        <dd>
          <Input
            value={userDataReducer.email}
            onChange={(e) => {
              changeValue(e, "EMAIL");
            }}
          ></Input>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2">전화번호</dt>
        <dd>
          <Input
            value={userDataReducer.mobile}
            onChange={(e) => {
              changeValue(e, "MOBILE");
            }}
          ></Input>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2">주소</dt>
        <dd>
          <Input
            value={userDataReducer.address}
            onChange={(e) => {
              changeValue(e, "ADDRESS");
            }}
          ></Input>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2">유형</dt>
        <dd>
          <Select
            className="tw-w-full"
            value={userDataReducer.userType}
            onChange={(e) => {
              console.log(e);
              changeValue(e, "USERTYPE");
            }}
          >
            <Select.Option value="SUPERADMIN">SUPERADMIN</Select.Option>
            <Select.Option value="ADMIN">ADMIN</Select.Option>
          </Select>
        </dd>
      </dl>
      <dl>
        <dd className="tw-flex tw-justify-end">
          <Button
            type="primary"
            onClick={() => {
              mutation(userDataReducer).then(({ data, error }) => {
                if (error) {
                  console.log("faile");
                  messageApi.error("실패.");
                } else {
                  console.log("sc");
                  messageApi.success("수정되었습니다.");
                }
              });
            }}
          >
            적용
          </Button>
        </dd>
      </dl>
    </div>
  );
}
function UserPostTabContent({ userDataReducer }) {
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "100px",
    },
    {
      title: "board",
      dataIndex: "board",
      render: (i, l) => l.board.name,

      width: "130px",
    },
    {
      title: "title",
      dataIndex: "title",
    },

    {
      title: "modDt",
      dataIndex: "modDt",
      render: (t) => {
        const date = new Date(t);

        const year = date.getFullYear(); // 현재 연도
        const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
        const day = date.getDate(); // 현재 일

        return `${year}.${month}.${day}`;
      },
      width: "120px",
    },
  ];

  const [selectPost, setSelectPost] = useState(null);
  const rowClick = (rowData) => {
    setSelectPost(rowData);
    showModal();
  };

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={userDataReducer.posts}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              rowClick(record);
            }, // click row
          };
        }}
      ></Table>
      {selectPost == null ? null : (
        <PostModal
          selectPost={selectPost}
          open={open}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
}
function postReducerFn(state, action) {
  const type = {
    TITLE: "TITLE",
    CONTENT: "CONTENT",
    MIRODATA: "MIRODATA",
    BOARDTYPE: "BOARDTYPE",
  };
  switch (action.type) {
    case type.TITLE:
      state.title = action.value.target.value;
      break;

    case type.CONTENT:
      state.content = action.value.target.value;
      break;

    case type.MIRODATA:
      state.miroData = action.value.target.value;
      break;
    case type.BOARDTYPE:
      state.board.type = action.value;
      break;

    case "ALL":
      state = action.value;
      break;

    default:
      break;
  }

  return { ...state };
}
function PostModal({ selectPost, open, handleCancel }) {
  const viewAbleBoard = useGetViewAbleBoardQuery(selectPost.board.type);
  const [mutation] = useEditPostAdminMutation();
  const [messageApi, contextHolder] = message.useMessage();

  const [post, dispatch] = useReducer(postReducerFn, {
    ...selectPost,
  });
  useEffect(() => {
    dispatch({
      type: "ALL",
      value: { ...selectPost, content: decoding(selectPost.content) },
    });
  }, [selectPost]);

  const onOk = () => {
    mutation({ ...post, content: encoding(post.content) }).then(
      ({ data, error }) => {
        if (error) {
          messageApi.error(error.data);
          return;
        }
        messageApi.success("적용되었습니다.");
        handleCancel();
      }
    );
  };
  const postEdit = (e, value) => {
    dispatch({ type: value, value: e });
  };

  return !viewAbleBoard.isLoading ? (
    <Modal
      centered
      title="Title"
      open={open}
      onCancel={handleCancel}
      onOk={onOk}
    >
      {contextHolder}
      <dl className="tw-my-2">
        <dt className="tw-my-2">게시글 제목</dt>
        <dd>
          <Input
            value={post.title}
            onChange={(e) => {
              postEdit(e, "TITLE");
            }}
          ></Input>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2">게시판</dt>
        <dd>
          <Select className="tw-w-full" value={post.board.name}>
            {viewAbleBoard.data.map((d) => {
              return (
                <Select.Option key={d.id} value={d.name}>
                  {d.name}
                </Select.Option>
              );
            })}
          </Select>
        </dd>
      </dl>
      <dl className="tw-my-2">
        <dt className="tw-my-2">내용</dt>
        <dd>
          <TextArea
            value={post.content}
            onChange={(e) => {
              postEdit(e, "CONTENT");
            }}
          ></TextArea>
        </dd>
      </dl>
      {post.board.type === "MIRO" ? (
        <dl className="tw-my-2">
          <dt className="tw-my-2">미로데이터</dt>
          <dd>
            <TextArea value={post.miroData}></TextArea>
          </dd>
        </dl>
      ) : null}
    </Modal>
  ) : null;
}
function UserCommentTabContent({ userDataReducer }) {
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "100px",
    },
    {
      title: "content",
      dataIndex: "content",
      render: (i) => decoding(i),
    },

    {
      title: "modDt",
      dataIndex: "modDt",
      render: (t) => {
        const date = new Date(t);

        const year = date.getFullYear(); // 현재 연도
        const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
        const day = date.getDate(); // 현재 일

        return `${year}.${month}.${day}`;
      },
      width: "120px",
    },
  ];

  const [selectComment, setSelectComent] = useState(null);

  const rowClick = (rowData) => {
    setSelectComent(rowData);
    showModal();
  };

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={userDataReducer.comments}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              rowClick(record);
            }, // click row
          };
        }}
      ></Table>
      {selectComment === null ? null : (
        <CommentModal
          open={open}
          handleCancel={handleCancel}
          selectComment={selectComment}
        />
      )}
    </>
  );
}

function commentReducerFn(state, action) {
  const type = {
    CONTENT: "CONTENT",
  };
  switch (action.type) {
    case type.CONTENT:
      state.content = action.value.target.value;
      break;

    case "ALL":
      state = action.value;
      break;

    default:
      break;
  }

  return { ...state };
}

function CommentModal({ selectComment, open, handleCancel }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [mutation] = useEditCommentAdminMutation();
  const [comment, dispatch] = useReducer(commentReducerFn, {
    ...selectComment,
  });
  useEffect(() => {
    dispatch({
      type: "ALL",
      value: { ...selectComment, content: decoding(selectComment.content) },
    });
  }, [selectComment]);

  const onOk = () => {
    mutation({ ...comment, content: encoding(comment.content) }).then(
      ({ data, error }) => {
        if (error) {
          messageApi.error(error.data);
          return;
        }
        messageApi.success("적용되었습니다.");
        handleCancel();
      }
    );
  };

  return (
    <Modal
      centered
      title="Title"
      open={open}
      onCancel={handleCancel}
      onOk={onOk}
    >
      {contextHolder}
      <dl>
        <dt>시퀀스</dt>
        <dd>{comment.id}</dd>
      </dl>
      <dl>
        <dt>내용</dt>
        <dd>
          <Input
            value={comment.content}
            onChange={(e) => {
              dispatch({ type: "CONTENT", value: e });
            }}
          ></Input>
        </dd>
      </dl>
    </Modal>
  );
}
