import {createSlice} from "@reduxjs/toolkit";

const MainStore = createSlice({
    name: "Main",
    initialState: {
        value: 0,
        // serverIp:'192.168.0.36:3001',
        // server:'http://192.168.0.36:3001',
        serverIp: 'localhost:3001',
        server: process.env.REACT_APP_MIROBAEK_API,
        loginToken: localStorage.getItem('loginToken')

    },
    reducers: {},
});

export default MainStore;
