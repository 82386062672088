import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./View/Main";
import { Provider } from "react-redux";
import Store from "./Store/Store";
import ProblemList from "./View/miro/ProblemList";
import ProblemWrite from "./View/miro/PrblemWrite";
import Admin from "./View/admin/Admin";
import AdminMain from "./View/admin/AdminMain";
import UserList from "./View/admin/Main/User/UserList";
import NormalBoardAdd from "./View/admin/Main/Board/Normal/NormalBoardAdd";
import MiroBoardAdd from "./View/admin/Main/Board/Miro/MiroBoardAdd";
import QaBoardAdd from "./View/admin/Main/Board/QA/QaBoardAdd";
import NormalBoardList from "./View/admin/Main/Board/Normal/NormalBoardList";
import MiroBoardList from "./View/admin/Main/Board/Miro/MiroBoardList";
import QaBoardList from "./View/admin/Main/Board/QA/QaBoardList";
import ProblemView from "View/miro/ProblemView";
import MiroPostList from "View/admin/Main/Board/Miro/MiroPostList";
import ComponentTest from "Component/Layout/ComponentTest";
import PostList from "View/post/PostList";
import PostWrite from "./View/post/PostWrite";
import PostView from "View/post/PostView";
import App from "App";
import AdminLogin from "View/admin/AdminLogin";
import AdminList from "./View/admin/Main/User/AdminList";
import AddAdmin from "View/admin/Main/User/AddAdmin";
import NormalPostList from "View/admin/Main/Board/Normal/NormalPostList";
import MyPage from "View/mypage/MyPage";

console.log(process.env)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <App>
      <BrowserRouter>
        <Routes>
          <Route path="test/" element={<ComponentTest />} />

          <Route path="" element={<Main />} />

          <Route path="mypage/" element={<MyPage />}></Route>

          <Route path="problem/">
            <Route path="list/" element={<ProblemList />} />
            <Route path="write/" element={<ProblemWrite />} />
            <Route path="view/:postId" element={<ProblemView />}></Route>
          </Route>
          <Route path="post/">
            <Route path="list/" element={<PostList />} />
            <Route path="write/" element={<PostWrite />} />
            <Route path="view/:postId" element={<PostView />}></Route>
          </Route>

          {/* 관리자 S */}
          <Route
            path="admin/login/"
            element={<AdminLogin></AdminLogin>}
          ></Route>

          <Route path="admin/" element={<Admin />}>
            <Route path="" element={<AdminMain></AdminMain>}>
              <Route path="" element={<UserList />}>
                <Route path="" element={<UserList />} />
              </Route>
            </Route>

            <Route path="main/" element={<AdminMain />}>
              <Route path="" element={<UserList />}>
                <Route path="" element={<UserList />} />
              </Route>

              <Route path="user/" element={<UserList />}>
                <Route path="list/" element={<UserList />} />
              </Route>

              <Route path="admin/">
                <Route path="list/" element={<AdminList />} />
                <Route path="add/" element={<AddAdmin />} />
              </Route>

              <Route path="board/">
                <Route path="normal/">
                  <Route
                    path="add/"
                    element={<NormalBoardAdd></NormalBoardAdd>}
                  ></Route>
                  <Route
                    path="list/"
                    element={<NormalBoardList></NormalBoardList>}
                  ></Route>
                  <Route
                    path="post/"
                    element={<NormalPostList></NormalPostList>}
                  ></Route>
                </Route>
                <Route path="miro/">
                  <Route
                    path="add/"
                    element={<MiroBoardAdd></MiroBoardAdd>}
                  ></Route>
                  <Route
                    path="board/"
                    element={<MiroBoardList></MiroBoardList>}
                  ></Route>
                  <Route
                    path="post/"
                    element={<MiroPostList></MiroPostList>}
                  ></Route>
                </Route>
                <Route path="qa/">
                  <Route
                    path="add/"
                    element={<QaBoardAdd></QaBoardAdd>}
                  ></Route>
                  <Route
                    path="list/"
                    element={<QaBoardList></QaBoardList>}
                  ></Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* 관리자 E */}
        </Routes>
      </BrowserRouter>
    </App>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
