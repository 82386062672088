import { PicLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { Link, Outlet } from "react-router-dom";

const adminSider = [
  {
    key: `1`,
    icon: React.createElement(UserOutlined),
    label: `유저관리`,
    children: [
      {
        key: 1_1,
        label: <Link to="/admin/main/user/list">유저관리</Link>,
      },
      {
        key: 1_2,
        label: <Link to="/admin/main/admin/add">관리자 계정 추가</Link>,
      },
      {
        key: 1_3,
        label: <Link to="/admin/main/admin/list">관리자 계정 관리</Link>,
      },
    ],
  },
  {
    key: `2`,
    icon: React.createElement(PicLeftOutlined),
    label: `일반 게시판 관리`,
    children: [
      {
        key: 2_1,
        label: <Link to="/admin/main/board/normal/add">게시판 추가</Link>,
      },
      {
        key: 2_2,
        label: <Link to="/admin/main/board/normal/list">게시판 관리</Link>,
      },
      {
        key: 2_3,
        label: <Link to="/admin/main/board/normal/post">게시글 관리</Link>,
      },
    ],
  },

  {
    key: `3`,
    icon: React.createElement(PicLeftOutlined),
    label: `미로 게시판 관리`,
    children: [
      {
        key: 3_1,
        label: <Link to="/admin/main/board/miro/add">게시판 추가</Link>,
      },
      {
        key: 3_2,
        label: <Link to="/admin/main/board/miro/board">게시판 관리</Link>,
      },
      {
        key: 3_3,
        label: <Link to="/admin/main/board/miro/post">게시글 관리</Link>,
      },
    ],
  },
  {
    key: `4`,
    icon: React.createElement(PicLeftOutlined),
    label: `문의 게시판 관리`,
    children: [
      {
        key: 4_1,
        label: <Link to="/admin/main/board/qa/add">게시판 추가</Link>,
      },
      {
        key: 4_2,
        label: <Link to="/admin/main/board/qa/list">게시판 관리</Link>,
      },
      {
        key: 4_3,
        label: "게시글 관리",
      },
    ],
  },
];

export default function Main() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="tw-h-full">
      <Sider
        width={200}
        style={{
          background: colorBgContainer,
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{
            height: "100%",
            borderRight: 0,
          }}
          items={adminSider}
        />
      </Sider>

      {/* 메인콘텐츠 */}
      <Outlet></Outlet>
    </Layout>
  );
}
