import { createSlice } from "@reduxjs/toolkit";

const UserStore = createSlice({
  name: "User",
  initialState: {
    loginToken: localStorage.getItem("loginToken"),
    loginData: {
      userId: "",
      memId: "",
      userType: "",
    },
  },
  reducers: {
    setLoginToken(state, payload) {
      state.loginToken = payload.payload;
      localStorage.setItem("loginToken", state.loginToken);
      let data = JSON.parse(atob(state.loginToken.split(".")[1]));
      state.loginData.memId = data.memId;
      state.loginData.userId = data.userId;
      state.loginData.userType = data.userType;

    },
    logout(state) {
      localStorage.removeItem("loginToken");
      state.loginToken = "";
      state.loginData = {
        userId: "",
        memId: "",
      };
    },
  },
});

export default UserStore;
