export default function MiroBlock(props) {
  const data = props.data;
  const clickBlock = props.clickBlockEv;

  const style = {
    block: "tw-w-10 tw-h-10  ",
  };

  switch (data.blockInfo) {
    case 0:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-white tw-border-solid  tw-border-gray-200 tw-border-opacity-90 tw-border-2 ${style.block} `}
          ></div>
        </>
      );

    case 1:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-gray-600 ${style.block} `}
          ></div>
        </>
      );
    case 2:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-green-500 ${style.block} `}
          ></div>
        </>
      );

    case 3:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-blue-600 ${style.block}`}
          ></div>
        </>
      );
    case 4:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-gray-400 ${style.block} `}
          >
            {/* {data.index_i},{data.index_l} */}
          </div>
        </>
      );
    case 5:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-orange-400 ${style.block} `}
          >
            {/* {data.index_i},{data.index_l} */}
          </div>
        </>
      );

    case 6:
      return (
        <>
          <div
            onClick={clickBlock}
            className={`tw-bg-lime-400 ${style.block} `}
          ></div>
        </>
      );

    default:
      return (
        <>
          <div onClick={clickBlock} className={`tw-bg-red-500 ${style.block}`}>
            {props.data}
          </div>
        </>
      );
  }
}
