import { Button, Radio } from "antd";
import Input from "antd/es/input/Input";
import axios from "axios";
import { useReducer } from "react";
import { useSelector } from "react-redux";
import ManagementLayout from "../../../../../Component/Layout/Admin/ManagementLayout";

export default function MiroBoardAdd() {
  
  const store= useSelector(state=>state)
  
  const newBoard = {
    name: "",
    active: false,
    comment: false,
    type:'MIRO'
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setName":
        return { ...state, name: action.value };

      case "setActive":
        return { ...state, active: action.value };

      case "setComment":
        return { ...state, comment: action.value };

      default:
        return { ...action };
    }
  };

  const [requestBoardData, dispatch] = useReducer(reducer, newBoard);

  const newBoardPs = ()=>{
    console.log(requestBoardData)
    axios.post(`${store.Main.server}/admin/boardmanagement/add`,{...requestBoardData},{
      headers: {
        Authorization: `Bearer ${store.User.loginToken}`,
      },
    })
    .then(function(response){
      console.log(response.data)
    })
    .catch(function(err){
      console.log(err)
    })

    
  }

  const style = {
    dl: "",
    dt: "",
  };

  return (
    <ManagementLayout>
      <div>
        <h1>미로 게시판 추가</h1>
      </div>
      <div>
        <dl className={(style.dl = "tw-my-4")}>
          <dt className={(style.dt = "tw-my-2")}>게시판 이름</dt>
          <dd>
            <Input value={requestBoardData.name}
                onChange={(e)=>{
                    dispatch({type:"setName",value:e.currentTarget.value})
                }} 

            ></Input>
          </dd>
        </dl>

        <div className="tw-flex ">
          <dl
            className={`${style.dl} tw-mx-4 tw-border-solid tw-border-0 tw-border-r-2  tw-border-gray-200`}
          >
            <dt className={style.dt} >게시판 활성화</dt>
            <dd>
              <Radio.Group value={requestBoardData.active} 
              onChange={(e)=>{

                console.log(e)
                dispatch({type:"setActive",value:e.target.value})
            }} 
              >
                <Radio value={true}>활성화</Radio>
                <Radio value={false}>비활성화</Radio>
              </Radio.Group>
            </dd>
          </dl>

          <dl className={`${style.dl} tw-mx-4 `}>
            <dt className={style.dt}>덧글 허용</dt>

            <dd>
              <Radio.Group value={requestBoardData.comment}
              onChange={(e)=>{
                dispatch({type:"setComment",value:e.target.value})
            }} 
              >
                <Radio value={true}>허용</Radio>
                <Radio value={false}>비허용</Radio>
              </Radio.Group>
            </dd>
          </dl>
        </div>

        <dl className={style.dl}>
          <dd>
            <Button type="primary" onClick={newBoardPs} >추가</Button>
          </dd>
        </dl>
      </div>
    </ManagementLayout>
  );
}
