import ManagementLayout from "../../../../Component/Layout/Admin/ManagementLayout";
import { Table } from "antd";
import { useGetUserListQuery } from "Store/MyApi";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (text) => <a href="/">{text}</a>,
  },
  {
    title: "UserId",
    dataIndex: "memId",
    key: "memId",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },

  {
    title: "regDt",
    dataIndex: "regDt",
    key: "regDt",
    render: (dt) => {
      const date = new Date(dt);

      const year = date.getFullYear(); // 현재 연도
      const month = date.getMonth() + 1; // 현재 월 (0부터 시작하므로 +1 필요)
      const day = date.getDate(); // 현재 일

      return `${year}.${month}.${day}`;
    },
  },
];
// const data = [
//   {
//     Id: "1",
//     UserId: "5563a",
//     Email: "abc@naver.com",
//     regDt: "2023.05.23",
//   },
//   {
//     Id: "2",
//     UserId: "testuser",
//     Email: "abc@naver.com",
//     regDt: "2023.05.23",
//   },
//   {
//     Id: "3",
//     UserId: "testuser2",
//     Email: "abc@naver.com",
//     regDt: "2023.05.23",
//   },
// ];

export default function AdminList() {
  const userList = useGetUserListQuery("USER");

  console.log(userList.data);

  return (
    <ManagementLayout>
      <h1>유저관리</h1>
      <Table
        columns={columns}
        loading={userList.isLoading}
        dataSource={userList.data}
        rowKey={"id"}
      />
    </ManagementLayout>
  );
}
