import { configureStore } from "@reduxjs/toolkit";
import MainStore from "./Main";
import UserStore from "./User";
import Miro from "./Miro";
import { myApi } from "./MyApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import TestStore from "./Test";
const Store = configureStore({
  reducer: {
    Main: MainStore.reducer,
    User: UserStore.reducer,
    Miro: Miro.reducer,
    Test: TestStore.reducer,
    [myApi.reducerPath]: myApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(myApi.middleware),
});

setupListeners(Store.dispatch);

export default Store;
