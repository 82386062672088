import { Button, Input } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function AdminLogin() {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loginRequest, setLoginRequest] = useState({
    memId: "",
    memPw: "",
  });

  const loginPs = () => {
    console.log("로그인");
    axios
      .post(`${store.Main.server}/user/login`, loginRequest)
      .then((response) => {
        let loginToken = response.data;
        console.log(loginToken);
        navigation("/admin");
        dispatch({ type: "User/setLoginToken", payload: loginToken });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="tw-flex tw-h-full tw-h-screen tw-w-1/2 tw-justify-center tw-items-center ">
      <div className="tw-flex-grow">
        <Link to="/">
          <div className="  tw-p-7">
            <h2 className="tw-font-light  tw-text-base tw-text-center ">
              ─ ADMIN ─
            </h2>
            <h1 className="tw-font-normal tw-text-center tw-text-4xl">
              MiroBaek
            </h1>
            <h1 className="tw-font-light tw-text-xl tw-text-center ">
              resolve <span className="tw-text-blue-700">Miro</span>
            </h1>
          </div>
        </Link>
      </div>

      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center  tw-h-1/2  tw-flex-grow tw-border-solid tw-border-0 tw-border-l-2 tw-border-gray-100  ">
        <div className=" tw-h-1/3 tw-flex tw-flex-col tw-justify-around ">
          <dl className="tw-my-2">
            <dt className="tw-my-2">아이디</dt>
            <dd>
              <Input
                onChange={(e) => {
                  setLoginRequest({
                    ...loginRequest,
                    memId: e.currentTarget.value,
                  });
                }}
              ></Input>
            </dd>
          </dl>
          <dl className="tw-my-2">
            <dt className="tw-my-2">비밀번호</dt>
            <dd>
              <Input
                onChange={(e) => {
                  setLoginRequest({
                    ...loginRequest,
                    memPw: e.currentTarget.value,
                  });
                }}
                type="password"
              ></Input>
            </dd>
          </dl>
          <Button
            onClick={loginPs}
            type="primary"
            className="tw-w-full tw-my-2 "
          >
            로그인
          </Button>
        </div>
      </div>
    </div>
  );
}
