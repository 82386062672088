import { Button, Input, message, Space } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useSelector } from "react-redux";

export default function Join(props) {
  const store = useSelector((state) => state);

  const [roadAddress, setRoadAddress] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const joinFaile = () => {
    messageApi.open({
      type: "error",
      content: "비어있는 항목을 체크해주세요",
    });
  };

  const [joinRequest, setJoinRequest] = useState({
    memId: "",
    memPw: "",
    memPwRe: "",
    email: "",
    mobile: "",
    address: "",
  });

  const joinPs = () => {
    console.log(joinRequest);

    setJoinRequest({
      ...joinRequest,
      address: `(${zoneCode}) ${roadAddress} ${joinRequest.address}`,
    });

    axios
      .post(`${store.Main.server}/user/join`, joinRequest)
      .then((response) => {
        console.log(response);
        props.joinScCallBack ? props.joinScCallBack() : console.log("콜백 x");
      })
      .catch((err) => {
        console.log(err);
        joinFaile();
      });
  };

  const style = {
    dl: "",
    dt: "",
    dd: "",
  };

  const inputAddressEv = PostcodeOpenEv(
    setZoneCode,
    setRoadAddress,
    setJoinRequest,
    joinRequest,
    detailAddress
  );

  return (
    <div className=" tw-border-2 tw-flex tw-flex-col tw-justify-center tw-h-full tw-p-5">
      {contextHolder}
      <h1 className="tw-text-3xl tw-my-3">회원가입</h1>
      <div className="tw-ml-3">
        <dl className={(style.dl = " tw-flex tw-my-4 ")}>
          <dt
            className={
              (style.dt =
                " tw-mr-5 tw-w-32 tw-flex tw-items-center tw-justify-between after:tw-content-[''] after:tw-inline-block after:tw-h-full after:tw-w-1 after:tw-bg-gray-200")
            }
          >
            아이디
          </dt>

          <dd className={(style.dd = "tw-flex-grow")}>
            <Input
              value={joinRequest.memId}
              onChange={(e) =>
                setJoinRequest({ ...joinRequest, memId: e.target.value })
              }
            />
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>비밀번호</dt>

          <dd className={style.dd}>
            <Input.Password
              value={joinRequest.memPw}
              onChange={(e) =>
                setJoinRequest({ ...joinRequest, memPw: e.target.value })
              }
            />
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>비밀번호 확인</dt>

          <dd className={style.dd}>
            <Input.Password
              value={joinRequest.memPwRe}
              onChange={(e) =>
                setJoinRequest({ ...joinRequest, memPwRe: e.target.value })
              }
            />
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>이메일</dt>

          <dd className={style.dd}>
            <Input
              value={joinRequest.email}
              onChange={(e) =>
                setJoinRequest({ ...joinRequest, email: e.target.value })
              }
            />
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>휴대전화 번호</dt>

          <dd className={style.dd}>
            <Input
              value={joinRequest.mobile}
              onChange={(e) =>
                setJoinRequest({ ...joinRequest, mobile: e.target.value })
              }
            />
          </dd>
        </dl>

        <dl className={style.dl}>
          <dt className={style.dt}>주소</dt>

          <dd className={style.dd}>
            <Space.Compact style={{ width: "100%" }}>
              <Input value={zoneCode} readOnly />
              <Button type="primary" onClick={inputAddressEv}>
                찾기
              </Button>
            </Space.Compact>
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>
            <span></span>
          </dt>

          <dd className={style.dd}>
            <Input readOnly value={roadAddress}></Input>
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>
            <span></span>
          </dt>

          <dd className={style.dd}>
            <Input
              value={detailAddress}
              onChange={(e) => {
                setDetailAddress(e.currentTarget.value);
                setJoinRequest({
                  ...joinRequest,
                  address: `(${zoneCode}) ${roadAddress} ${detailAddress}`,
                });
              }}
              placeholder="상세주소를 입력해주세요"
            ></Input>
          </dd>
        </dl>
        <dl className={style.dl}>
          <dt className={style.dt}>
            <span></span>
          </dt>

          <dd className={style.dd}>
            <Input value={joinRequest.address}></Input>
          </dd>
        </dl>
      </div>
      <Space wrap className="tw-justify-end">
        <button className="tw-border-0 tw-p-1.5 tw-rounded-lg tw-w-24   hover:tw-bg-slate-400 hover:tw-text-white  tw-transition-all">
          취소
        </button>

        <button
          onClick={joinPs}
          className="tw-border-0 tw-p-1.5 tw-rounded-lg tw-w-24 tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600 tw-transition-all "
        >
          회원가입
        </button>
      </Space>
    </div>
  );
}

const PostcodeOpenEv = (
  setZoneCode,
  setRoadAddress,
  setJoinRequest,
  joinRequest,
  detailAddress
) => {
  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'

    const resultData = {
      zonecode: data.zonecode,
      roadAddress: data.roadAddress,
    };
    setZoneCode(data.zonecode);

    setRoadAddress(data.roadAddress);

    setJoinRequest({
      ...joinRequest,
      address: `(${data.zonecode}) ${data.roadAddress} ${detailAddress}`,
    });

    console.log(resultData);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return handleClick;
};
