import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { encoding } from "Modul/Base64";

export const myApi = createApi({
  reducerPath: "myApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MIROBAEK_API,
  }),
  tagTypes: ["comment"],
  endpoints: (builder) => ({
    getWriteAbleBoard: builder.query({
      /**
       * 작성할 수 있는 게시판
       * @param {*} type NORMAL,MIRO,QA
       * @returns
       */
      query: (boardType) => `board/findbytype?type=${boardType}`,
    }),
    getViewAbleBoard: builder.query({
      /**
       * 볼수 있는 게시판
       * @param {*} boardType
       * @returns
       */
      query: (boardType) => `board/findbytype?type=${boardType}`,
    }),

    getPostList: builder.query({
      /**
       * 게시판의 게시글 리스트
       * @param {*} boardId
       * @returns
       */
      query: (boardId) => `post/list?boardId=${boardId}`,
      keepUnusedDataFor: 1,
    }),
    // getPost: builder.query({
    //   query: (postId) => `post/get?postId=${postId}`,
    // }),
    getPost: builder.mutation({
      query: (postId) => ({
        url: `post/get?postId=${postId}`,
        method: "GET",
      }),
    }),
    getPostData: builder.query({
      query: (postId) => `post/get?postId=${postId}`,
    }),
    getCommentList: builder.query({
      query: (postId) => `comment/view?postId=${postId}`,
      providesTags: (result, error, arg) => {
        return [{ type: "comment", id: arg }];
      },
    }),
    validUserToken: builder.query({
      query: () => ({
        url: "user/valid",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
        },
      }),
      keepUnusedDataFor: 1,
    }),
    writeComment: builder.mutation({
      query: ({ postId, content }) => ({
        url: "comment/write",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          postId: postId,
          content: encoding(content),
        }),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "comment", id: arg.postId }];
      },
    }),
    writeReComment: builder.mutation({
      query: ({ commentId, content }) => ({
        url: "recomment/write",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: {
          commentId: commentId,
          content: encoding(content),
        },
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "comment", id: arg.postId }];
      },
    }),
    resolveMiroComplate: builder.mutation({
      query: (postId) => ({
        url: "resolvehistory/add",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: {
          id: postId,
        },
      }),
    }),
    getUserList: builder.query({
      query: (userType) => ({
        url: `admin/usermanagement/findbytype?userType=${userType}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
        },
      }),
    }),
    addAdmin: builder.mutation({
      query: (newAdmin) => ({
        url: "admin/usermanagement/addadmin",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: newAdmin,
      }),
    }),
    myPost: builder.query({
      query: () => ({
        url: "post/mypost",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    myComment: builder.query({
      query: () => ({
        url: "comment/mycomment",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    loginUserInfo: builder.query({
      query: () => ({
        url: "user/login",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    editLoginUserInfo: builder.mutation({
      query: (editUser) => ({
        url: "user/login",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: editUser,
      }),
    }),
    editUserInfoAdmin: builder.mutation({
      query: (editUser) => ({
        url: "admin/usermanagement",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: editUser,
      }),
    }),
    editCommentAdmin: builder.mutation({
      query: (editComment) => ({
        url: "admin/commentmanagement",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: editComment,
      }),
    }),
    editPostAdmin: builder.mutation({
      query: (editPost) => ({
        url: "admin/postmanagement",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: editPost,
      }),
    }),
    getPostListByType: builder.query({
      query: (type) => ({
        url: "admin/postmanagement/get",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
        body: { type: type },
      }),
    }),
    getUserInfoAdmin: builder.query({
      query: (userId) => ({
        url: `admin/usermanagement/${userId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
      }),
    }),

    myResolveHistory: builder.query({
      query: () => ({
        url: "resolvehistory/myhistory",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useGetWriteAbleBoardQuery,
  useGetViewAbleBoardQuery,
  useGetPostListQuery,
  useGetPostMutation,
  useGetCommentListQuery,
  useValidUserTokenQuery,
  useGetPostDataQuery,
  useWriteCommentMutation,
  useWriteReCommentMutation,
  useResolveMiroComplateMutation,
  useGetUserListQuery,
  useAddAdminMutation,
  useMyPostQuery,
  useMyCommentQuery,
  useMyResolveHistoryQuery,
  useLoginUserInfoQuery,
  useEditLoginUserInfoMutation,
  useGetPostListByTypeQuery,
  useGetUserInfoAdminQuery,
  useEditUserInfoAdminMutation,
  useEditPostAdminMutation,
  useEditCommentAdminMutation,
} = myApi;
