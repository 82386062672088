import { Button, Input, Select, Tabs } from "antd";
import { useCallback, useState } from "react";
import MainLayout from "../../Component/Layout/MainLayout";
import {
  PictureOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "Modul/axios";
import { useNavigate } from "react-router-dom";
import messageApi from "Component/MessageApi";
import { useGetWriteAbleBoardQuery } from "Store/MyApi";
import { useDropzone } from "react-dropzone";

export default function PostWrite() {
  const MessageApi = messageApi();

  const store = useSelector((state) => state);

  const [editorData, setEditorData] = useState({
    title: "",
    content: "",
  });

  const navigation = useNavigate();

  const [selectBoard, setSelectBoard] = useState(undefined);

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `글`,
      children: (
        <WiteContent
          setSelectBoard={setSelectBoard}
          editorData={editorData}
          setEditorData={setEditorData}
        ></WiteContent>
      ),
    },
    {
      key: "2",
      label: `옵션`,
      children: `Content of Tab Pane 3`,
    },
  ];

  function validRequestData() {
    if (selectBoard === undefined) {
      throw new Error("게시판을 선택해주세요");
    }

    console.log(editorData.content.title);
    if (editorData.title === "") {
      throw new Error("게시글의 제목을 입력해주세요");
    }

    console.log(editorData.content.content);
    if (editorData.content === "") {
      throw new Error("내용을 입력해주세요");
    }
  }

  const writePostPS = () => {
    // console.log(editorData)
    // console.log(store.Miro.miroData)
    // console.log(selectBoard)

    try {
      validRequestData();
    } catch (error) {
      MessageApi.error(error.message);
      return;
    }

    let requestData;
    requestData = {
      boardId: selectBoard.value,
      content: btoa(unescape(encodeURIComponent(editorData.content))),
      miroData: "",
      title: editorData.title,
    };

    console.log(requestData);

    axios()
      .post(`${store.Main.server}/post/write`, requestData)
      .then(function (response) {
        console.log(response.data.id);
        navigation(`/post/view/${response.data.id}`);
      })
      .catch(function (err) {
        if (err.response.status === 403) {
          MessageApi.error("로그아웃 상태입니다.");
        }
      });
  };

  return (
    <MainLayout>
      {MessageApi.contextHolder}
      <main className=" tw-w-full tw-flex tw-flex-col tw-items-center ">
        <section className=" tw-w-2/3 tw-mt-4 tw-flex tw-flex-col tw-items-center tw-justify-center  ">
          <Tabs
            className="tw-w-full "
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />

          <div className=" tw-border-solid tw-border-0 tw-border-t-2 tw-border-gray-100 tw-pt-3 tw-flex tw-w-full tw-mt-4 tw-justify-end ">
            <Button type="primary" className="tw-mx-2" onClick={writePostPS}>
              글올리기
            </Button>
          </div>
        </section>
      </main>
    </MainLayout>
  );
}

function WiteContent(props) {
  const editorData = props.editorData;
  const setEditorData = props.setEditorData;

  const setSelectBoard = props.setSelectBoard;

  const { data, error, isLoading } = useGetWriteAbleBoardQuery("NORMAL");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData({ ...editorData, content: data });
  };

  const titleOnChage = (e) => {
    setEditorData({ ...editorData, title: e.currentTarget.value });
  };

  const onFileDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const fileType = file.type;
        const isImage = fileType.startsWith("image/");

        if (isImage) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result;

            const data =
              editorData.content +
              `<img  src='${base64String}' style='max-width: "150px";'></img> `;
            setEditorData({ ...editorData, content: data });
          };
        }
      });
    },
    [editorData, setEditorData]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
  });

  return (
    <div className=" tw-flex tw-flex-col" id="outer">
      <Input
        value={editorData.title}
        className="tw-mb-5"
        addonBefore="제목"
        addonAfter={
          <Select
            defaultValue="선택해주세요"
            onChange={(e, data) => {
              console.log(e);
              console.log(data);
              setSelectBoard(data);
            }}
          >
            {error ? null : isLoading ? null : data ? (
              <>
                {data.map((d) => {
                  return (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  );
                })}
              </>
            ) : null}
          </Select>
        }
        onChange={titleOnChage}
      ></Input>

      <CKEditor
        editor={ClassicEditor}
        data={editorData.content}
        config={{
          toolbar: [
            "heading",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "decreaseIndent",
            "increaseIndent",
            "blockquote",
            "insertTable",
            "insertMedia",
            "undo",
            "redo",
          ],
        }}
        onReady={(editor) => {}}
        onChange={handleEditorChange}
      />
      <div className="tw-mt-4">
        <div
          id="imgDropArea"
          className="tw-w-full tw-bg-gray-100 tw-h-36 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-flex-col "
          {...getRootProps()}
        >
          <p>
            <PictureOutlined
              style={{ fontSize: "60px", textAlign: "center", color: "gray" }}
            />
          </p>
          {isDragActive ? (
            <p>파일을 여기에 드롭하세요!</p>
          ) : (
            <p>파일을 드래그하여 업로드하거나, 파일을 선택하세요.</p>
          )}{" "}
        </div>
      </div>
    </div>
  );
}
