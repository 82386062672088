import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginPopup from "../Login/LoginPopup";

export default function MainLayout(props) {
  return (
    <>
      <Header></Header>
      {props.children}
    </>
  );
}

export function Header() {
  return (
    <header className="    tw-border-0 tw-border-b-2 tw-border-gray-100 tw-border-solid  tw-w-full tw-flex tw-justify-center   ">
      <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-24 tw-w-2/3">
        <Link to="/">
          <div className="tw-p-7">
            <h1 className="tw-font-normal tw-text-4xl">MiroBaek</h1>
            <h1 className="tw-font-light tw-text-xl tw-text-center ">
              resolve <span className="tw-text-blue-700">Miro</span>
            </h1>
          </div>
        </Link>

        <div className=" tw-min-w-max  tw-flex tw-w-3/5 tw-flex-col  tw-h-full tw-justify-end ">
          <div className="tw-p-1 tw-pt-2 tw-flex tw-justify-end tw-pb-4   ">
            <UserArea></UserArea>
          </div>
          <nav className="tw-flex tw-w-full tw-items-end   ">
            <ul className="tw-flex tw-justify-end  tw-w-full tw-text-sm  ">
              <li className="tw-border-0 tw-py-2 tw-border-solid tw-border-b-2 tw-border-white hover:tw-border-blue-600 hover:tw-transition-all tw-transition-all   ">
                <Link className="tw-px-20  " to="/problem/list">
                  문제풀기
                </Link>
              </li>
              <li className="tw-border-0 tw-py-2 tw-border-solid tw-border-b-2 tw-border-white hover:tw-border-blue-600 hover:tw-transition-all tw-transition-all   ">
                <Link className="tw-px-20" to="/post/list">
                  게시판
                </Link>
              </li>
              {/* <li className="tw-px-3">랭킹</li>
              <li className="tw-px-3">문의하기</li>
              <li className="tw-px-3">항목1</li>
              <li className="tw-px-3">항목1</li> */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

function UserArea() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  useEffect(() => {}, [store.User.loginToken, store.User.loginData]);

  if (
    localStorage.getItem("loginToken") === "" ||
    localStorage.getItem("loginToken") === null
  ) {
    return <LoginPopup></LoginPopup>;
  } else {
    try {
      // loginToken = localStorage.getItem("loginToken").split(".")[1];
      // console.log(JSON.parse(atob(loginToken)));
    } catch (error) {
      return <LoginPopup></LoginPopup>;
    }

    return (
      <p className="tw-flex tw-items-center">
        <span className="tw-text-sm">{store.User.loginData.memId}</span>
        <Link to={"/mypage"} className="tw-p-1 tw-ml-4 tw-text-sm ">
          마이페이지
        </Link>
        <button
          className="tw-p-1 tw-mx-4 tw-text-sm "
          onClick={() => {
            localStorage.removeItem("loginToken");
            dispatch({ type: "User/logout", payload: "" });
          }}
        >
          로그아웃
        </button>
      </p>
    );
  }
}
